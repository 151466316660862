// src/mixins/ConnectionMixin.js
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isOnline: navigator.onLine, // Initial connection status
      
    };
  },
  methods: {
  
    checkConnection() {
      if (!this.isOnline) {
        Swal.fire({
          icon: "warning",
          title: "No Internet Connection",
          text: "Please connect your internet connection!",
          confirmButtonText: "Okay",
        });
        return false;
      }
      return true;
    },
    handleOffline() {
      this.isOnline = false;
      Swal.fire({
        icon: "warning",
        title: "No Internet Connection",
        text: "Please connect your internet connection!",
        confirmButtonText: "Okay",
      });
    },
    handleOnline() {
      this.isOnline = true;
      Swal.close();
    },
  },
  mounted() {
    // Listen for online/offline events
    window.addEventListener("offline", this.handleOffline);
    window.addEventListener("online", this.handleOnline);
  },
  beforeUnmount() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener("offline", this.handleOffline);
    window.removeEventListener("online", this.handleOnline);
  },
};
