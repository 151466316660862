<template>

  <!-- Menu -->

  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <router-link to="/home" class="app-brand-link">
        <img style="width: 100%" src="/assets/img/logo/BOW.png" alt="" />
      </router-link>
      <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>


      <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>

    <hr />

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1">
      <!-- Dashboard -->
      <!-- <li class="menu-item active">
              <a href="index.html" class="menu-link">
                <i class="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Dashboard</div>
              </a>
            </li> -->

      <li class="menu-item" :class="{ active: $route.path === '/home' }">
        <router-link to="/home" class="menu-link">
          <i class="menu-icon fi fi-rr-dashboard"></i>
          <div data-i18n="Dashboards">Dashboards</div>
        </router-link>
      </li>
    
       <li class="menu-item" :class="{ active: $route.path === '/register-user-list' }">
        <router-link to="/register-user-list" class="menu-link">
          <i class="menu-icon fi fi-rr-list"></i>
          <div data-i18n="users">Registered Customer</div>
        </router-link>
      </li>
      <li class="menu-item" :class="{ active: $route.path === '/add-new-container' }">
        <router-link to="/add-new-container" class="menu-link">
          <i class="menu-icon fi fi-rr-truck-container"></i>
          <div data-i18n="Port">Add Containers</div>
        </router-link>
      </li>
      <li class="menu-item text-start" :class="{ active: $route.path === '/container-search' }">
        <router-link to="/container-search" class="menu-link">
          <i class="menu-icon fi fi-rr-search"></i>
          <div data-i18n="Port">Search Container</div>
        </router-link>
      </li>
      
      <li class="menu-item" :class="{ active: $route.path === '/active-containers' }">
        <router-link to="/active-containers" class="menu-link">
          <i class="menu-icon fi fi-rr-list"></i>
          <div data-i18n="Port">Active Containers</div>
        </router-link>
      </li>
      <li class="menu-item" :class="{ active: $route.path === '/container-under-booking' }">
        <router-link to="/container-under-booking" class="menu-link text-start">
          <i class="menu-icon fi fi-rr-list"></i>
          <div data-i18n="Dashboards">Active Bookings</div>
        </router-link>
      </li>
      <li class="menu-item">
        <span class="menu-link text-truncate cursor-pointer active" data-bs-toggle="collapse"
          data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample"
          @click="toggleCollapse">
          <i class="menu-icon fi fi-rr-recycle"></i>
          <div data-i18n="Re Use Container">Re Use Container</div>
          <i :class="isCollapsed ? 'fi fi-rr-angle-right' : 'fi fi-rr-angle-down'
            " style="font-size: 12px; right: 10px; position: absolute"></i>
        </span>

        <div class="collapse" id="collapseExample">
          <ul>
            <li class="menu-item text-start">
              <router-link to="/reuse-container/add-details" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Linking Requests</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/reuse-container/delinking-request-list" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Delinking Requests</div>
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="menu-item text-start" :class="{ active: $route.path === '/payment-pending' }">
        <router-link to="/payment-pending" class="menu-link">
          <i class="menu-icon fi fi-rs-transaction-globe"></i>
          <div data-i18n="Port">Payments Pending</div>
        </router-link>
      </li>
      <!-- <li class="menu-item text-start" :class="{ active: $route.path === '/stats-report' }">
        <router-link to="/stats-report" class="menu-link">
          <i class="menu-icon fi fi-rr-chart-histogram"></i>
          <div data-i18n="Port">Stats Report</div>
        </router-link>
      </li> -->

      <li class="menu-item text-start" :class="{ active: $route.path === '/notification-list' }">
        <router-link to="/notification-list" class="menu-link">
          <i class="menu-icon fi fi-rr-bell-notification-social-media"></i>

          <div data-i18n="Port">Notification</div>
        </router-link>
      </li>

      <li class="menu-item text-start" :class="{ active: $route.path === '/history' }">
        <router-link to="/history" class="menu-link">
          <i class="menu-icon fi fi-rr-time-past"></i>

          <div data-i18n="Port">History</div>
        </router-link>
      </li>


      <li class="menu-item">
        <span class="menu-link text-truncate cursor-pointer active" data-bs-toggle="collapse"
          data-bs-target="#collapseExampleMaster" aria-expanded="true" aria-controls="collapseExampleMaster"
          @click="toggleCollapse">
       

          <i class="menu-icon fi fi-rr-settings"></i>
          <div data-i18n="Re Use Container">Settings</div>
          <i :class="isCollapsed ? 'fi fi-rr-angle-right' : 'fi fi-rr-angle-down'
            " style="font-size: 12px; right: 10px; position: absolute"></i>
        </span>

        <div class="collapse" id="collapseExampleMaster">
          <ul>
            <li class="menu-item text-start">
              <router-link to="/master/country" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Country</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/state" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">State</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/district" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">District</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/taluka-list" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Talukas</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/village-area-list" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Village / Area</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/state-pincode-ranges" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">State Pin Code Ranges</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/booking-rejection-reasons" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Booking Rejection Reason</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/admin-rejection-reasons" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Admin Rejection Reason</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/exporter-rejection-reasons" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Exporter Rejection Reason</div>
              </router-link>
            </li>

            <li class="menu-item text-start">
              <router-link to="/master/shippingline-rejection-reasons" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Shippingline Rejection Reason</div>
              </router-link>
            </li>

            <li class="menu-item text-start">
              <router-link to="/master/shippingline-delink-reasons" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Shippingline Delink Reason</div>
              </router-link>
            </li>

            <li class="menu-item text-start">
              <router-link to="/master/shipping-line-charges" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Pricing</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/check-out-points" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Check Out Points</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/port" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Port</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/axle-list" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Truck Axle Weight Category</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/commodity-list" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Commodity Type in Container</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link to="/master/market-price" class="menu-link cursor-pointer">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Market Price</div>
              </router-link>
            </li>
          </ul>
        </div>
      </li>


    </ul>
  </aside>
  <!-- / Menu -->

</template>

<script>

export default {
  data() {
    return {
      isMenuOpen: {
        master: false,
        another: false,
      },
      isCollapsed: true, // default state for collapse
    };
  },
  mounted() {
    const script = document.createElement('script');
    script.src = '/assets/vendor/js/menu.js';
    document.head.appendChild(script);
  },
  methods: {

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    isCurrentRoute(route) {
      return this.$route.path === route;
    },
    toggleMenu(menu) {
      this.isMenuOpen[menu] = !this.isMenuOpen[menu];
      this.updateMenuState(menu);
    },

  },

};
</script>
