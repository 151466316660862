
export default {
  data() {
    return {
        isSidebarOpen: this.$store.state.isSidebarOpen, // Track the state of the sidebar
    };
  },
  methods: {
   

    toggleSidebar() {
        this.$store.state.isSidebarOpen = !this.isSidebarOpen;
  
      },
  
  },

};
